import "./Icon.css";

import { ReactComponent as Add } from "./img/Add.svg";
import { ReactComponent as Blind } from "./img/Blind.svg";
import { ReactComponent as Close } from "./img/Close.svg";
import { ReactComponent as Compare } from "./img/Compare.svg";
import { ReactComponent as Details } from "./img/Details.svg";
import { ReactComponent as Dropdown } from "./img/Dropdown.svg";
import { ReactComponent as Explore } from "./img/Explore.svg";
import { ReactComponent as Filter } from "./img/Filter.svg";
import { ReactComponent as Glassdoor } from "./img/Glassdoor.svg";
import { ReactComponent as Go } from "./img/Go.svg";
import { ReactComponent as Levels } from "./img/Levels.svg";
import { ReactComponent as Linkedin } from "./img/Linkedin.svg";
import { ReactComponent as Indeed } from "./img/Indeed.svg";
import { ReactComponent as Individual } from "./img/Individual.svg";
import { ReactComponent as Payscale } from "./img/Payscale.svg";
import { ReactComponent as Search } from "./img/Search.svg";
import { ReactComponent as Skillshare } from "./img/Skillshare.svg";
import { ReactComponent as Sort } from "./img/Sort.svg";
import { ReactComponent as Udacity } from "./img/Udacity.svg";
import { ReactComponent as Udemy } from "./img/Udemy.svg";

export const Icon = ({ variant = "add", color = "purple", size = "sm" }) => {
  const colorClass = `icon-color--${color}`;
  const sizeClass = `icon-size--${size}`;

  return (
    <>
      <div className={`${colorClass} ${sizeClass}`}>
        {(() => {
          switch (variant) {
            case "add":
              return <Add className="icon-img" />;
            case "blind":
              return <Blind className="icon-img" />;
            case "close":
              return <Close className="icon-img" />;
            case "compare":
              return <Compare className="icon-img" />;
            case "details":
              return <Details className="icon-img" />;
            case "dropdown":
              return <Dropdown className="icon-img" />;
            case "explore":
              return <Explore className="icon-img" />;
            case "filter":
              return <Filter className="icon-img" />;
            case "glassdoor":
              return <Glassdoor className="icon-img" />;
            case "go":
              return <Go className="icon-img" />;
            case "levels":
              return <Levels className="icon-img" />;
            case "linkedin":
              return <Linkedin className="icon-img" />;
            case "indeed":
              return <Indeed className="icon-img" />;
            case "individual":
              return <Individual className="icon-img" />;
            case "payscale":
              return <Payscale className="icon-img" />;
            case "search":
              return <Search className="icon-img" />;
            case "skillshare":
              return <Skillshare className="icon-img" />;
            case "sort":
              return <Sort className="icon-img" />;
            case "udacity":
              return <Udacity className="icon-img" />;
            case "udemy":
              return <Udemy className="icon-img" />;
            default:
              return <Add className="icon-img" />;
          }
        })()}
      </div>
    </>
  );
};
