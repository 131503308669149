import React from "react";

import "./DetailsPage.css";

import Card from "../../components/Card";
import Heading from "../../components/Heading";
import Placeholder from "../../components/Placeholder";
import Text from "../../components/Text";
import TextLink from "../../components/TextLink";
import LinkGroup from "../../components/LinkGroup";
import { useFetch } from "../../hooks/useFetch";
import HistoricalTrends from "../../components/HistoricalTrends";

export const DetailsPage = ({
  dataFromParent,
  setSelectedObject,
  isSubItem = false,
  parentObj,
}) => {
  const data = useFetch(`${process.env.REACT_APP_API || ""}/api/items`, {
    options: {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({
        items: [
          {
            name: dataFromParent?.name,
            category: dataFromParent?.category,
          },
        ],
      }),
    },
    ignoreIfTrue: isSubItem && !dataFromParent,
    watch: [dataFromParent],
    transformer: (data) => data[0],
  });

  const infoData = useFetch(
    `https://en.wikipedia.org/w/api.php?action=query&prop=extracts&exintro&explaintext&exsentences=2&origin=*&format=json&generator=search&gsrnamespace=0&gsrlimit=1&gsrsearch=${encodeURIComponent(
      dataFromParent?.name +
        " " +
        (dataFromParent?.category === "Company" ? "company" : "")
    )}`,
    {
      ignoreIfTrue: !dataFromParent || !data,
      transformer: (data) => {
        if (data.query) {
          for (const id in data.query.pages) {
            const obj = data.query.pages[id];

            if (obj.extract.length > 0) {
              return {
                id,
                title: obj.title,
                info: obj.extract.substring(0, 150) + "...",
              };
            }
          }
        }
      },
    }
  );

  return (
    <>
      <div className="details">
        {isSubItem ? (
          <Heading variant="h2">
            {dataFromParent ? dataFromParent.name : "Details"}
          </Heading>
        ) : null}
        {isSubItem && !dataFromParent ? (
          <>
            <Text>Click on a result to see related information.</Text>
          </>
        ) : data ? (
          <>
            {isSubItem ? (
              <Card
                color="blue"
                style={{
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                <Heading variant="h3">Fast facts</Heading>
                <Text>
                  <strong>{data.name}</strong> is the{" "}
                  <strong>
                    #
                    {parentObj.data
                      .filter((item) => item.category === data.category)
                      .sort((a, b) => b.listings - a.listings)
                      .findIndex((item) => item.name === data.name) + 1}{" "}
                    {data.category.toLocaleLowerCase()}
                  </strong>{" "}
                  for {parentObj.name}
                </Text>
                <Text>
                  <strong>{data.name}</strong> has{" "}
                  <strong>
                    {parseInt(
                      parentObj.data.find((item) => item.name === data.name)
                        .listings
                    ).toLocaleString("en", {
                      useGrouping: true,
                    })}{" "}
                    listings
                  </strong>{" "}
                  for {parentObj.name}{" "}
                  {data.listings.length > 0
                    ? `out of a total of ${parseInt(
                        data.listings[0].listings
                      ).toLocaleString("en", {
                        useGrouping: true,
                      })}`
                    : null}
                </Text>
                <Text>
                  {parentObj.name} is the{" "}
                  <strong>
                    #
                    {data.data
                      .filter((item) => item.category === parentObj.category)
                      .sort((a, b) => b.listings - a.listings)
                      .findIndex((item) => item.name === parentObj.name) +
                      1}{" "}
                    {parentObj.category.toLocaleLowerCase()}
                  </strong>{" "}
                  for <strong>{data.name}</strong>
                </Text>
              </Card>
            ) : null}
            <div className="description">
              <Card
                color={isSubItem ? "blue" : "white"}
                style={{
                  width: "auto",
                  maxWidth: "100%",
                }}
              >
                <Heading variant="h3">Description</Heading>
                {infoData ? (
                  <>
                    <Text>{infoData.info}</Text>
                    <TextLink
                      href={`https://en.wikipedia.org/wiki/${encodeURIComponent(
                        infoData.title
                      )}`}
                      target="_blank"
                    >
                      Read more on Wikipedia
                    </TextLink>
                  </>
                ) : null}
              </Card>
            </div>
            <div className="trend">
              <HistoricalTrends
                color={isSubItem ? "blue" : "white"}
                localScopeName={parentObj?.name}
                textFormat={`%VIEW% %CYCLE% %TIME_PERIOD% ${
                  isSubItem ? "%SCOPE% %ADJUSTMENT%" : ""
                }`}
                data={[data]}
                parentObj={parentObj}
              />
            </div>
            {!isSubItem ? (
              <>
                <div className="related">
                  <LinkGroup
                    heading="Search for jobs"
                    headingVariant="h3"
                    primaryColor={isSubItem ? "blue" : "white"}
                    data={[
                      {
                        name: "LinkedIn",
                        href: `https://www.linkedin.com/jobs/search/?keywords=${encodeURIComponent(
                          dataFromParent.name
                        )}`,
                        icon: "linkedin",
                      },
                      {
                        name: "Glassdoor",
                        href: `https://www.glassdoor.com/Search/results.htm?keyword=${encodeURIComponent(
                          dataFromParent.name
                        )}`,
                        icon: "glassdoor",
                      },
                      {
                        name: "Indeed",
                        href: `https://indeed.com/jobs?q=${encodeURIComponent(
                          dataFromParent.name
                        )}`,
                        icon: "indeed",
                      },
                    ]}
                  />
                </div>
                <div className="context">
                  <LinkGroup
                    heading="Useful tools"
                    headingVariant="h3"
                    primaryColor={isSubItem ? "blue" : "white"}
                    data={(() => {
                      switch (dataFromParent.category) {
                        case "Company":
                          return [
                            {
                              name: "Info on Glassdoor",
                              href: `https://www.glassdoor.com/Search/results.htm?keyword=${encodeURIComponent(
                                dataFromParent.name
                              )}`,
                              icon: "glassdoor",
                            },
                            {
                              name: "Salaries on Levels.fyi",
                              href: `https://www.levels.fyi/companies`,
                              icon: "levels",
                            },
                            {
                              name: "Reviews on Blind",
                              href: `https://www.teamblind.com/search/${encodeURIComponent(
                                dataFromParent.name
                              )}`,
                              icon: "blind",
                            },
                          ];
                        case "Title":
                          return [
                            {
                              name: "Info on Glassdoor",
                              href: `https://www.glassdoor.com/Search/results.htm?keyword=${encodeURIComponent(
                                dataFromParent.name
                              )}`,
                              icon: "glassdoor",
                            },
                            {
                              name: "Salaries on Levels.fyi",
                              href: `https://www.levels.fyi/t`,
                              icon: "levels",
                            },
                            {
                              name: "Salaries on Payscale",
                              href: `https://www.payscale.com/rcsearch.aspx?str=${encodeURIComponent(
                                dataFromParent.name
                              )}`,
                              icon: "payscale",
                            },
                            {
                              name: "Salaries on Indeed",
                              href: `https://www.indeed.com/career/${encodeURIComponent(
                                dataFromParent.name
                              )}/salaries`,
                              icon: "indeed",
                            },
                          ];
                        case "Skill":
                          return [
                            {
                              name: "Learn on Linkedin",
                              href: `https://www.linkedin.com/learning/search?keywords=${encodeURIComponent(
                                dataFromParent.name
                              )}`,
                              icon: "linkedin",
                            },
                            {
                              name: "Learn on Skillshare",
                              href: `https://www.skillshare.com/search?query=${encodeURIComponent(
                                dataFromParent.name
                              )}`,
                              icon: "skillshare",
                            },
                            {
                              name: "Learn on Udacity",
                              href: `https://www.udacity.com/courses/all?search=${encodeURIComponent(
                                dataFromParent.name
                              )}`,
                              icon: "udacity",
                            },
                            {
                              name: "Learn on Udemy",
                              href: `https://www.udemy.com/courses/search/?q=${encodeURIComponent(
                                dataFromParent.name
                              )}`,
                              icon: "udemy",
                            },
                          ];
                        default:
                          return null;
                      }
                    })()}
                  />
                </div>
              </>
            ) : null}
            {isSubItem ? (
              <LinkGroup
                heading="Dive deeper"
                headingVariant="h3"
                primaryColor="blue"
                data={[
                  {
                    name: `Explore${
                      window.innerWidth > 1000
                        ? " " + data.category.toLocaleLowerCase()
                        : ""
                    }`,
                    onClick: () => setSelectedObject(data),
                    icon: "explore",
                  },
                ]}
              />
            ) : (
              <div className="metadata">
                <Card
                  color="white"
                  style={{
                    width: "fit-content",
                    height: "min-content",
                  }}
                >
                  <Heading variant="h3">
                    {parseInt(data.listings[0].listings).toLocaleString("en", {
                      useGrouping: true,
                    })}
                  </Heading>
                  <Text variant="caption">Listings</Text>
                </Card>
                <Card
                  color="white"
                  style={{
                    width: "fit-content",
                    height: "min-content",
                  }}
                >
                  <Heading variant="h3">{data.category}</Heading>
                  <Text variant="caption">Category</Text>
                </Card>
              </div>
            )}
          </>
        ) : (
          <>
            {isSubItem ? (
              <Placeholder
                style={{
                  height: "4rem",
                  width: "100%",
                }}
              />
            ) : null}
            <div className="description">
              <Placeholder
                style={{
                  height: "6rem",
                  width: "100%",
                }}
              />
            </div>
            <div className="trend">
              <Placeholder
                style={{
                  height: "28rem",
                  width: "100%",
                }}
              />
            </div>
            {!isSubItem ? (
              <>
                <div className="related">
                  <Placeholder
                    style={{
                      height: "14rem",
                      width: "100%",
                    }}
                  />
                </div>
                <div className="context">
                  <Placeholder
                    style={{
                      height: "10rem",
                      width: "100%",
                    }}
                  />
                </div>
              </>
            ) : null}
            {isSubItem ? (
              <Placeholder
                style={{
                  height: "8rem",
                  width: "100%",
                }}
              />
            ) : (
              <div className="metadata">
                <Placeholder
                  style={{
                    height: "5rem",
                    width: "12ch",
                  }}
                />
                <Placeholder
                  style={{
                    height: "5rem",
                    width: "12ch",
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
