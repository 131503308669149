import React from "react";

import "./TextLink.css";

import Button from "../Button";
import Text from "../Text";

export const TextLink = ({
  variant = "link",
  icon,
  color,
  href,
  onClick,
  target,
  children,
}) => {
  switch (variant) {
    case "button":
      return (
        <a
          href={href}
          target={target}
          rel="noreferrer"
          className="textlink textlink-button"
        >
          <Button
            withIcon={icon ? true : false}
            iconVariant={icon}
            color={color}
            label={children}
            onClick={onClick}
          />
        </a>
      );
    case "link":
      return (
        <a
          href={href}
          target={target}
          rel="noreferrer"
          className={`textlink ${variant}`}
        >
          <span className="textlink-label">{children}</span>
        </a>
      );
    default:
      return (
        <div className="textlink textlink-custom">
          <Text variant={variant} onClick={onClick}>
            {children}
          </Text>
        </div>
      );
  }
};
