import React from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import "./SignInPage.css";

import Input from "../../components/Input";
import Heading from "../../components/Heading";
import Text from "../../components/Text";
import Card from "../../components/Card";
import Button from "../../components/Button";
import TextLink from "../../components/TextLink";

import cloud_1 from "../../images/cloud.svg";
import cloud_2 from "../../images/cloud--2.svg";

export const SignInPage = ({ setSearchValue }) => {
  const { user, loading, errorMessage, signIn, setErrorMessage } = useAuth();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const ERRORS = {
    INVALID_EMAIL: "Enter a valid email address.",
    INCORRECT_PASSWORD: "Email or password is incorrect.",
  };

  const handleSignIn = () => {
    if (!new RegExp("^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$").test(email)) {
      setErrorMessage(ERRORS.INVALID_EMAIL);
      return;
    }
    signIn(email, password);
  };

  const history = useHistory();

  return (
    <div className="signin">
      <div className="top-left-cloud">
        <img src={cloud_1} />
      </div>
      <Card
        variant="default"
        color="white"
        style={{
          padding: "36px",
          zIndex: "1",
          maxWidth: "90vw",
        }}
      >
        <Heading>welcome back</Heading>
        <Text>it's good to see you.</Text>
        <br />
        <div className="signin-inputs">
          <Input
            placeholder="email"
            withIcon={false}
            label="email"
            value={email}
            setValue={(value) => {
              setEmail(value);
              setErrorMessage(null);
            }}
            style={{
              width: "100%",
            }}
          />
          <Input
            placeholder="password"
            withIcon={false}
            label="password"
            value={password}
            setValue={(value) => {
              setPassword(value);
              setErrorMessage(null);
            }}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              width: "100%",
            }}
            type="password"
          />
          {errorMessage && (
            <Text variant="error" className="errorMessage">
              {errorMessage}
            </Text>
          )}
          <div className="signin-inputs--cta">
            <Button label="Log In" color="cta" onClick={handleSignIn}></Button>
            <TextLink href="/signup">
              Don't have an account? Sign up here!
            </TextLink>
          </div>
        </div>
      </Card>
      <div className="bottom-right-cloud">
        <img src={cloud_2} />
      </div>
    </div>
  );
};
