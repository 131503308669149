import React from "react";

import "./Dropdown.css";

import Button from "../Button";
import Card from "../Card";
import TextLink from "../TextLink";
import Icon from "../Icon";

export const Dropdown = ({
  variant = "button",
  color = "blue",
  options,
  iconSize = "xs",
  value,
  setValue,
}) => {
  const [active, setActive] = React.useState(false);
  const activeOption = options.find((opt) => opt.value === value);

  return (
    <div className={`dropdown dropdown-variant--${variant}`}>
      {active ? (
        variant === "button" ? (
          <div className="dropdown-card">
            <Card
              color={color}
              style={{
                width: "min-content",
                padding: "8px",
              }}
            >
              <div className="dropdown-button">
                <Button
                  label={activeOption.label}
                  withIcon={true}
                  iconVariant="dropdown"
                  forceActive={true}
                  onClick={() => {
                    setActive(false);
                  }}
                />
              </div>
              {options
                .filter((el) => el.value !== value)
                .map((opt, i) => {
                  return (
                    <div key={i} className="dropdown-button">
                      <Button
                        color={color === "white" ? "blue" : "white"}
                        withIcon={typeof opt.icon !== "undefined"}
                        iconVariant={opt.icon}
                        forceActive={opt.value === value}
                        label={opt.label}
                        onClick={() => {
                          setActive(false);
                          setValue(opt.value);
                        }}
                      />
                    </div>
                  );
                })}
            </Card>
          </div>
        ) : (
          <>
            <div
              className="dropdown-text"
              onClick={() => {
                setActive(false);
              }}
            >
              <TextLink variant={variant}>
                <span className="dropdown-text--inner">
                  {activeOption.label}
                  <Icon variant="dropdown" size={iconSize} />
                </span>
              </TextLink>
            </div>
            <div className="dropdown-card">
              <Card
                color={color}
                style={{
                  width: "min-content",
                  padding: "8px",
                }}
              >
                {options
                  .filter((el) => el.value !== value)
                  .map((opt, i) => {
                    return (
                      <div key={i} className="dropdown-button">
                        <Button
                          color={color === "white" ? "blue" : "white"}
                          withIcon={typeof opt.icon !== "undefined"}
                          iconVariant={opt.icon}
                          forceActive={opt.value === value}
                          label={opt.label}
                          onClick={() => {
                            setActive(false);
                            setValue(opt.value);
                          }}
                        />
                      </div>
                    );
                  })}
              </Card>
            </div>
          </>
        )
      ) : variant === "button" ? (
        <Button
          color={color}
          label={activeOption.label}
          withIcon={true}
          iconVariant="dropdown"
          forceActive={active}
          onClick={() => {
            setActive(true);
          }}
        />
      ) : (
        <div
          className="dropdown-text"
          onClick={() => {
            setActive(true);
          }}
        >
          <TextLink variant="custom">
            <span className="dropdown-text--inner">
              {activeOption.label}
              <Icon variant="dropdown" size={iconSize} />
            </span>
          </TextLink>
        </div>
      )}
    </div>
  );
};
