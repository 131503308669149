import React from "react";

import "./LinkGroup.css";

import Card from "../Card";
import Heading from "../Heading";
import TextLink from "../TextLink";

export const LinkGroup = ({
  data,
  heading,
  headingVariant,
  primaryColor = "blue",
}) => {
  return (
    <Card
      color={primaryColor}
      style={{
        width: "auto",
        maxWidth: "100%",
      }}
    >
      <Heading variant={headingVariant}>{heading}</Heading>
      <div className="linkgroup">
        {data.map((link, i) => {
          return (
            <TextLink
              key={i}
              variant="button"
              target={link.target ? link.target : "_blank"}
              icon={link.icon}
              href={link.href}
              onClick={link.onClick}
              color={primaryColor === "white" ? "blue" : "white"}
            >
              {link.name}
            </TextLink>
          );
        })}
      </div>
    </Card>
  );
};
