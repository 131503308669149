import React from "react";

import "./OverviewPage.css";

import Card from "../../components/Card";
import Heading from "../../components/Heading";
import Icon from "../../components/Icon";
import Placeholder from "../../components/Placeholder";
import RadioGroup from "../../components/RadioGroup";
import Text from "../../components/Text";

import DetailsPage from "../DetailsPage";
import ComparePage from "../ComparePage";

import { useFetch } from "../../hooks/useFetch";
import DataTransformer from "../../components/DataTransformer";
import Button from "../../components/Button";

const VIEWS = {
  DETAILS: "details",
  COMPARE: "compare",
};

const SORTS = {
  ALPHA: "Alphabetical",
  LISTINGS: "Listings",
  ASCENDING: "Ascending",
  DESCENDING: "Descending",
};

const FILTERS = {
  TOP_10: "Top 10",
  TOP_25: "Top 25",
  TOP_50: "Top 50",
  TOP_100: "Top 100",
  NONE: "No filters",
};

export const OverviewPage = ({ selectedObject, setSelectedObject }) => {
  const [showFilter, setShowFilter] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState(FILTERS.TOP_10);
  const [showSort, setShowSort] = React.useState(false);
  const [sortDirection, setSortDirection] = React.useState(SORTS.DESCENDING);
  const [sortValue, setSortValue] = React.useState(SORTS.LISTINGS);
  const [fns, setFns] = React.useState({
    filter: (data) => [...data].slice(0, 10),
    sort: (data, direction) =>
      [...data].sort((a, b) =>
        direction === SORTS.ASCENDING
          ? a.listings - b.listings
          : b.listings - a.listings
      ),
  });

  const [view, setView] = React.useState(VIEWS.DETAILS);
  const [mobileShowing, setMobileShowing] = React.useState(false);
  const [mobileScrolling, setMobileScrolling] = React.useState(true);
  const mobileShowingRef = React.useRef();

  mobileShowingRef.current = mobileShowing;

  const [searchData, setSearchData] = React.useState(undefined);
  const data = useFetch(
    `${process.env.REACT_APP_API || ""}/api/overview?name=${encodeURIComponent(
      selectedObject.name
    )}&category=${encodeURIComponent(selectedObject.category)}`
  );

  React.useEffect(() => {
    if (!data) return;

    setSearchData(fns.sort(fns.filter(data.data), sortDirection));
  }, [filterValue, sortValue, data, sortDirection]);

  const [detailObject, setDetailObject] = React.useState();
  const [compareObject, setCompareObject] = React.useState([]);

  const toggleRef = React.useRef();
  const contextualRef = React.useRef();
  const contextualInnerRef = React.useRef();

  React.useEffect(() => {
    setDetailObject(undefined);
    setCompareObject([]);
  }, [selectedObject]);

  React.useEffect(() => {
    if (
      window.innerWidth > 1000 ||
      !toggleRef.current ||
      !contextualRef.current ||
      !contextualInnerRef.current
    )
      return;
    const toggle = toggleRef.current;
    const contextual = contextualRef.current;
    const contextualInner = contextualInnerRef.current;

    const emptyMouseData = {
      tracking: false,
      currentY: 0,
      lastY: 0,
      startY: 0,
    };
    let mouseState = emptyMouseData;

    const handleStartBuilder = (getY) => {
      return (e) => {
        mouseState = {
          tracking: true,
          currentY: getY(e),
          lastY: getY(e),
          startY: getY(e),
        };

        contextual.classList.add("no-transition");
      };
    };
    const handleMoveBuilder = (getY) => {
      return (e) => {
        if (mouseState.tracking) {
          if (e.cancelable) e.preventDefault();

          mouseState = {
            tracking: true,
            currentY: getY(e),
            lastY: mouseState.currentY,
            startY: mouseState.startY,
          };

          contextual.style.transform = `translateY(${Math.max(
            mobileShowingRef.current
              ? 0
              : -1 * contextualInner.getBoundingClientRect().height + 64,
            mouseState.currentY - mouseState.startY
          )}px)`;
        }
      };
    };
    const handleEnd = (e) => {
      if (mouseState.tracking) {
        if (e.cancelable) e.preventDefault();

        const delta = mouseState.currentY - mouseState.lastY;

        mouseState = emptyMouseData;
        contextual.style.transform = "";
        contextual.style.overflow = "";

        contextual.classList.remove("no-transition");

        if (delta === 0) {
          if (mobileShowingRef.current) {
            setMobileShowing(false);
          } else {
            setMobileShowing(true);
          }
        } else if (delta > 0) {
          setMobileShowing(false);
        } else {
          setMobileShowing(true);
        }
      }
    };
    const handleTouchStart = handleStartBuilder((e) => e.touches[0].clientY);
    const handleTouchMove = handleMoveBuilder((e) => e.touches[0].clientY);
    const handleMouseDown = handleStartBuilder((e) => e.clientY);
    const handleMouseMove = handleMoveBuilder((e) => e.clientY);

    toggle.addEventListener("touchstart", handleTouchStart);
    toggle.addEventListener("touchmove", handleTouchMove);
    toggle.addEventListener("touchend", handleEnd);
    toggle.addEventListener("mousedown", handleMouseDown);
    toggle.addEventListener("mousemove", handleMouseMove);
    toggle.addEventListener("mouseup", handleEnd);
    toggle.addEventListener("mouseleave", handleEnd);

    return () => {
      toggle.removeEventListener("touchstart", handleTouchStart);
      toggle.removeEventListener("touchmove", handleTouchMove);
      toggle.removeEventListener("touchend", handleEnd);
      toggle.removeEventListener("mousedown", handleMouseDown);
      toggle.removeEventListener("mousemove", handleMouseMove);
      toggle.removeEventListener("mouseup", handleEnd);
      toggle.removeEventListener("mouseleave", handleEnd);
    };
  }, [toggleRef.current, contextualRef.current]);

  React.useEffect(() => {
    if (window.innerWidth > 1000) return;

    if (mobileShowing) {
      if (view !== VIEWS.COMPARE || compareObject.length > 1)
        setMobileScrolling(false);
      else setMobileScrolling(true);
    } else {
      setMobileScrolling(true);
    }
  }, [mobileShowing]);

  React.useEffect(() => {
    if (window.innerWidth > 1000) return;

    if (mobileScrolling) {
      if (document.body.classList.contains("no-scroll"))
        document.body.classList.remove("no-scroll");
    } else {
      document.body.classList.add("no-scroll");
    }
  }, [mobileScrolling]);

  React.useEffect(() => {
    if (window.innerWidth > 1000) return;
    if (!mobileShowing) return;

    if (
      compareObject.length <= 1 &&
      document.body.classList.contains("no-scroll")
    ) {
      document.body.classList.remove("no-scroll");
    } else if (compareObject.length > 1) {
      document.body.classList.add("no-scroll");
    }
  }, [compareObject]);

  return (
    <>
      <Heading variant="h1">{selectedObject.name}</Heading>
      <Heading variant="h2">Overview</Heading>
      <div className="overview">
        <DetailsPage dataFromParent={data} />
      </div>
      <Heading variant="h2">Related items</Heading>
      <div className="selectGroup">
        <Button
          color="white"
          label="Sort"
          withIcon={true}
          iconVariant="sort"
          forceActive={showSort}
          onClick={() => setShowSort(!showSort)}
        />
        <Button
          color="white"
          label="Filter"
          withIcon={true}
          iconVariant="filter"
          forceActive={showFilter}
          onClick={() => setShowFilter(!showFilter)}
        />
      </div>
      <div className="sort-filter">
        {showSort ? (
          <>
            <Card color="white">
              <Heading variant="h3">Sort</Heading>
              <Heading variant="h4">Order</Heading>
              <RadioGroup
                color="blue"
                options={[
                  {
                    label: SORTS.ASCENDING,
                    value: SORTS.ASCENDING,
                  },
                  {
                    label: SORTS.DESCENDING,
                    value: SORTS.DESCENDING,
                  },
                ]}
                value={sortDirection}
                setValue={setSortDirection}
              />
              <Heading variant="h4">Property</Heading>
              <DataTransformer
                color="blue"
                setFn={(fn) => {
                  setFns({
                    ...fns,
                    sort: fn,
                  });
                }}
                options={[
                  {
                    label: SORTS.ALPHA,
                    fn: (data, direction) =>
                      [...data].sort((a, b) =>
                        direction === SORTS.ASCENDING
                          ? a.name.localeCompare(b.name)
                          : b.name.localeCompare(a.name)
                      ),
                  },
                  {
                    label: SORTS.LISTINGS,
                    fn: (data, direction) =>
                      [...data].sort((a, b) =>
                        direction === SORTS.ASCENDING
                          ? a.listings - b.listings
                          : b.listings - a.listings
                      ),
                  },
                ]}
                value={sortValue}
                setValue={setSortValue}
              />
            </Card>
          </>
        ) : null}
        {showFilter ? (
          <>
            <Card color="white">
              <Heading variant="h3">Filter</Heading>
              <DataTransformer
                color="blue"
                setFn={(fn) => {
                  setFns({
                    ...fns,
                    filter: fn,
                  });
                }}
                options={[
                  {
                    label: FILTERS.TOP_10,
                    fn: (data) => [...data].slice(0, 10),
                  },
                  {
                    label: FILTERS.TOP_25,
                    fn: (data) => [...data].slice(0, 25),
                  },
                  {
                    label: FILTERS.TOP_50,
                    fn: (data) => [...data].slice(0, 50),
                  },
                  {
                    label: FILTERS.TOP_100,
                    fn: (data) => [...data].slice(0, 100),
                  },
                  {
                    label: FILTERS.NONE,
                    fn: (data) => data,
                  },
                ]}
                value={filterValue}
                setValue={setFilterValue}
              />
            </Card>
          </>
        ) : null}
      </div>
      <div className="overviewColumns">
        <div className="relevant">
          {searchData ? (
            <>
              {searchData.map((datum, i) => {
                return (
                  <div key={i} className="result">
                    <Card
                      variant="interactive"
                      color={
                        view === VIEWS.COMPARE
                          ? compareObject.some((d) => d.name === datum.name)
                            ? "purple10"
                            : "white"
                          : detailObject && detailObject.name === datum.name
                          ? "purple10"
                          : "white"
                      }
                      onClick={() => {
                        if (view === VIEWS.DETAILS) {
                          setDetailObject(datum);
                          setMobileShowing(true);
                          setMobileScrolling(false);
                        } else if (view === VIEWS.COMPARE) {
                          if (compareObject.length === 0)
                            setMobileShowing(true);
                          if (compareObject.some((d) => d.name === datum.name))
                            setCompareObject(
                              compareObject.filter((d) => d.name !== datum.name)
                            );
                          else setCompareObject([...compareObject, datum]);
                        }
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Heading variant="h3">{datum.name}</Heading>
                      <div className="resultData">
                        <Text>
                          <strong>Listings:</strong>
                        </Text>
                        <Text>
                          {datum.listings.toLocaleString("en", {
                            useGrouping: true,
                          })}
                        </Text>
                        <Text>
                          <strong>Category:</strong>
                        </Text>
                        <Text>{datum.category}</Text>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </>
          ) : (
            Array.from(Array(4)).map((_, i) => {
              return (
                <div key={i} className="result">
                  <Placeholder
                    style={{
                      height: "128px",
                      width: "100%",
                    }}
                  />
                </div>
              );
            })
          )}
        </div>
        <div className="contextual-outer" ref={contextualRef}>
          <div
            className={`contextual ${
              !detailObject && compareObject.length === 0 ? "" : "peeking"
            } ${mobileShowing ? "showing" : ""}`}
          >
            <div className="contextual-inner" ref={contextualInnerRef}>
              <div
                className="hide-on-desktop contextual-toggle"
                ref={toggleRef}
              >
                <Icon variant="dropdown" />
              </div>
              <div className="selectGroup contextual-selectGroup">
                <RadioGroup
                  color="blue"
                  options={[
                    {
                      label: "Details",
                      value: VIEWS.DETAILS,
                      icon: "details",
                    },
                    {
                      label: "Compare",
                      value: VIEWS.COMPARE,
                      icon: "compare",
                    },
                  ]}
                  value={view}
                  setValue={(newView) => {
                    setView(newView);
                    if (compareObject.length > 0) {
                      setDetailObject(compareObject[0]);
                      setCompareObject([]);
                      setMobileShowing(true);
                      setMobileScrolling(false);
                    } else if (detailObject) {
                      setDetailObject(undefined);
                      setCompareObject([detailObject]);
                      setMobileShowing(true);
                    } else {
                      setDetailObject(undefined);
                      setCompareObject([]);
                      setMobileShowing(true);
                    }
                  }}
                />
              </div>
              {(() => {
                switch (view) {
                  case VIEWS.DETAILS:
                    return (
                      <DetailsPage
                        dataFromParent={detailObject}
                        isSubItem={true}
                        setSelectedObject={(newObject) => {
                          setDetailObject(undefined);
                          setMobileShowing(false);
                          setSelectedObject(newObject);
                        }}
                        parentObj={data}
                      />
                    );
                  case VIEWS.COMPARE:
                    return (
                      <ComparePage
                        dataFromParent={compareObject}
                        setDataFromParent={setCompareObject}
                        setSelectedObject={(newObject) => {
                          setCompareObject([]);
                          setMobileShowing(false);
                          setSelectedObject(newObject);
                        }}
                        parentObj={data}
                      />
                    );
                  default:
                    return <></>;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
