import React from "react";

import "./ResultsPage.css";

import Button from "../../components/Button";
import Card from "../../components/Card";
import DataTransformer from "../../components/DataTransformer";
import Heading from "../../components/Heading";
import Placeholder from "../../components/Placeholder";
import RadioGroup from "../../components/RadioGroup";
import Text from "../../components/Text";

const FEATUREDLENGTH = 4;

const SORTS = {
  ALPHA: "Alphabetical",
  LISTINGS: "Listings",
  RELEVANCE: "Relevance",
  ASCENDING: "Ascending",
  DESCENDING: "Descending",
};

const FILTERS = {
  TOP_10: "Top 10",
  NONE: "Top 30",
};

export const ResultsPage = ({ query, data, ready, setSelectedObject }) => {
  const [showFilter, setShowFilter] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState(FILTERS.NONE);
  const [showSort, setShowSort] = React.useState(false);
  const [sortValue, setSortValue] = React.useState(SORTS.RELEVANCE);
  const [sortDirection, setSortDirection] = React.useState(SORTS.DESCENDING);
  const [fns, setFns] = React.useState({
    filter: (data) => data,
    sort: (data, direction) =>
      direction === SORTS.ASCENDING ? [...data].reverse() : data,
  });

  const [searchData, setSearchData] = React.useState(undefined);

  React.useEffect(() => {
    if (!data || !ready) return;

    setSearchData(fns.sort(fns.filter(data.results), sortDirection));
  }, [filterValue, sortValue, data, sortDirection]);

  return (
    <>
      <Heading variant="h1">Looking for &ldquo;{query}&rdquo;</Heading>
      <div className="results">
        <div className="featured">
          <Heading variant="h2">Featured results</Heading>
          {searchData ? (
            searchData.length > 0 ? (
              <div>
                <div className="selectGroup">
                  <Button
                    color="white"
                    label="Sort"
                    withIcon={true}
                    iconVariant="sort"
                    forceActive={showSort}
                    onClick={() => setShowSort(!showSort)}
                  />
                  <Button
                    color="white"
                    label="Filter"
                    withIcon={true}
                    iconVariant="filter"
                    forceActive={showFilter}
                    onClick={() => setShowFilter(!showFilter)}
                  />
                </div>
                <div className="sort-filter">
                  {showSort ? (
                    <>
                      <Card
                        color="white"
                        style={{
                          maxWidth: "100%",
                          marginBottom: "24px",
                        }}
                      >
                        <Heading variant="h3">Sort</Heading>
                        <Heading variant="h4">Order</Heading>
                        <RadioGroup
                          color="blue"
                          options={[
                            {
                              label: SORTS.ASCENDING,
                              value: SORTS.ASCENDING,
                            },
                            {
                              label: SORTS.DESCENDING,
                              value: SORTS.DESCENDING,
                            },
                          ]}
                          value={sortDirection}
                          setValue={setSortDirection}
                        />
                        <Heading variant="h4">Property</Heading>
                        <DataTransformer
                          color="blue"
                          setFn={(fn) => {
                            setFns({
                              ...fns,
                              sort: fn,
                            });
                          }}
                          options={[
                            {
                              label: SORTS.ALPHA,
                              fn: (data, direction) =>
                                [...data].sort((a, b) =>
                                  direction === SORTS.ASCENDING
                                    ? a.name.localeCompare(b.name)
                                    : b.name.localeCompare(a.name)
                                ),
                            },
                            {
                              label: SORTS.LISTINGS,
                              fn: (data, direction) =>
                                [...data].sort((a, b) =>
                                  direction === SORTS.ASCENDING
                                    ? a.listings - b.listings
                                    : b.listings - a.listings
                                ),
                            },
                            {
                              label: SORTS.RELEVANCE,
                              fn: (data, direction) =>
                                direction === SORTS.ASCENDING
                                  ? [...data].reverse()
                                  : data,
                            },
                          ]}
                          value={sortValue}
                          setValue={setSortValue}
                        />
                      </Card>
                    </>
                  ) : null}
                  {showFilter ? (
                    <>
                      <Card
                        color="white"
                        style={{
                          maxWidth: "100%",
                          marginBottom: "24px",
                        }}
                      >
                        <Heading variant="h3">Filter</Heading>
                        <DataTransformer
                          color="blue"
                          setFn={(fn) => {
                            setFns({
                              ...fns,
                              filter: fn,
                            });
                          }}
                          options={[
                            {
                              label: FILTERS.TOP_10,
                              fn: (data) => [...data].slice(0, 10),
                            },
                            {
                              label: FILTERS.NONE,
                              fn: (data) => data,
                            },
                          ]}
                          value={filterValue}
                          setValue={setFilterValue}
                        />
                      </Card>
                    </>
                  ) : null}
                </div>

                {searchData.slice(0, FEATUREDLENGTH).map((result, i) => {
                  return (
                    <div key={i} className="result">
                      <Card
                        variant="interactive"
                        color="white"
                        onClick={() => {
                          setSelectedObject(result);
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Heading variant="h3">{result.name}</Heading>
                        <div className="resultData">
                          <Text>
                            <strong>Listings:</strong>
                          </Text>
                          <Text>
                            {result.listings.toLocaleString("en", {
                              useGrouping: true,
                            })}
                          </Text>
                          <Text>
                            <strong>Category:</strong>
                          </Text>
                          <Text>{result.category}</Text>
                        </div>
                      </Card>
                    </div>
                  );
                })}
                {searchData.length > FEATUREDLENGTH ? (
                  <div className="more">
                    <Heading variant="h2">More results</Heading>
                    {!searchData
                      ? Array.from(Array(2 * FEATUREDLENGTH)).map((_, i) => {
                          return (
                            <div key={i} className="result">
                              <Placeholder
                                style={{
                                  height: "64px",
                                  width: "100%",
                                }}
                              />
                            </div>
                          );
                        })
                      : searchData.slice(FEATUREDLENGTH).map((result, i) => {
                          return (
                            <div key={i} className="result">
                              <Card
                                variant="interactive"
                                color="white"
                                onClick={() => {
                                  setSelectedObject(result);
                                }}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Heading variant="h6">{result.name}</Heading>
                              </Card>
                            </div>
                          );
                        })}
                  </div>
                ) : null}
              </div>
            ) : (
              <Text>No results to display!</Text>
            )
          ) : (
            Array.from(Array(FEATUREDLENGTH)).map((_, i) => {
              return (
                <div key={i} className="result">
                  <Placeholder
                    style={{
                      height: "128px",
                      width: "100%",
                    }}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};
