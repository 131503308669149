import React from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import "./SignUpPage.css";

import Input from "../../components/Input";
import Heading from "../../components/Heading";
import Text from "../../components/Text";
import Card from "../../components/Card";
import Button from "../../components/Button";
import TextLink from "../../components/TextLink";

import cloud_1 from "../../images/cloud.svg";
import cloud_2 from "../../images/cloud--2.svg";

export const SignUpPage = ({ setSearchValue }) => {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");

  const history = useHistory();
  const { signUp, errorMessage, setErrorMessage } = useAuth();

  const handleSignUp = () => {
    if (
      !new RegExp("^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$").test(email) ||
      password !== confirmPassword
    ) {
      return;
    }
    signUp(email, name, password);
  };

  return (
    <div className="signup">
      <div className="top-left-cloud">
        <img src={cloud_1} />
      </div>
      <Card
        variant="default"
        color="white"
        style={{
          padding: "36px",
          zIndex: "1",
          maxWidth: "90vw",
        }}
      >
        <Heading>new here?</Heading>
        <Text>you've found your silver lining.</Text>
        <br />
        <div className="signup-inputs">
          <Input
            placeholder="name"
            withIcon={false}
            label="name"
            value={name}
            setValue={setName}
            style={{
              width: "100%",
            }}
          />
          <Input
            placeholder="email"
            withIcon={false}
            label="email"
            value={email}
            setValue={(value) => {
              setErrorMessage();
              setEmail(value);
            }}
            style={{
              width: "100%",
            }}
            type="email"
            error={
              email.length === 0 ||
              new RegExp("^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$").test(email)
                ? undefined
                : "please enter a valid email"
            }
          />
          <Input
            placeholder="enter password"
            withIcon={false}
            label="enter password"
            value={password}
            setValue={setPassword}
            style={{
              width: "100%",
            }}
            error={
              new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$").test(
                password
              )
                ? undefined
                : "password should be more than 8 characters, containing at least one uppercase letter, one lowercase letter, and one digit"
            }
            type="password"
          />
          <Input
            placeholder="confirm password"
            withIcon={false}
            label="confirm password"
            value={confirmPassword}
            setValue={setConfirmPassword}
            style={{
              width: "100%",
            }}
            type="password"
            error={
              password !== confirmPassword
                ? "passwords do not match, try again"
                : undefined
            }
          />
          {errorMessage && (
            <Text variant="error" className="errorMessage">
              {errorMessage}
            </Text>
          )}
          <div className="signin-inputs--cta">
            <Button label="Sign Up" color="cta" onClick={handleSignUp}></Button>
            <TextLink href="/signin">
              Already have an account? Sign in here!
            </TextLink>
          </div>
        </div>
      </Card>
      <div className="bottom-right-cloud">
        <img src={cloud_2} />
      </div>
    </div>
  );
};
