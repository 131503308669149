import React from "react";

import "./Heading.css";

export const Heading = ({
  variant = "h1",
  tag,
  color = "purple",
  children,
}) => {
  const colorClass = `heading-color--${color}`;

  if (!tag) {
    tag = variant;
  }

  switch (tag) {
    case "h1":
      return <h1 className={`${colorClass} ${variant}`}>{children}</h1>;
    case "h2":
      return <h2 className={`${colorClass} ${variant}`}>{children}</h2>;
    case "h3":
      return <h3 className={`${colorClass} ${variant}`}>{children}</h3>;
    case "h4":
      return <h4 className={`${colorClass} ${variant}`}>{children}</h4>;
    case "h5":
      return <h5 className={`${colorClass} ${variant}`}>{children}</h5>;
    case "h6":
      return <h6 className={`${colorClass} ${variant}`}>{children}</h6>;
    case "cta":
      return <span className="cta">{children}</span>;
    default:
      return <h1 className={`${colorClass} ${variant}`}>{children}</h1>;
  }
};
