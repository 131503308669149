export const TIMEPERIOD = {
  PAST_MONTH: "for the past month",
  PAST_3_MONTHS: "for the past 3 months",
  PAST_6_MONTHS: "for the past 6 months",
  PAST_YEAR: "for the past year",
};

export const TIMEPERIODNUMS = {
  "for the past month": 1,
  "for the past 3 months": 3,
  "for the past 6 months": 6,
  "for the past year": 12,
};

export const TARGET = {
  SKILLS: "skills",
  TITLES: "titles",
  COMPANIES: "companies",
  COMPANIES_AND_TITLES: "companies and titles",
};

export const SCOPE = {
  GLOBAL: "across all items",
  LOCAL: "in this item only",
};

export const VIEW = {
  TOTAL: "Total listings",
  ABSOLUTE: "Change in listings",
  PERCENT: "Percent change in listings",
};

export const CYCLE = {
  MONTH_OVER_MONTH: "compared to the previous month",
  SINCE_START: "compared to the first month",
};

export const ADJUSTMENT = {
  NONE: "without adjustment",
  AVERAGE: "adjusted to the average for this item",
};
