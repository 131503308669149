import React from "react";

import "./ComparePage.css";

import Card from "../../components/Card";
import Heading from "../../components/Heading";
import HistoricalTrends from "../../components/HistoricalTrends";
import Placeholder from "../../components/Placeholder";
import Text from "../../components/Text";
import LinkGroup from "../../components/LinkGroup";

import { useFetch } from "../../hooks/useFetch";

export const ComparePage = ({
  dataFromParent,
  setDataFromParent,
  setSelectedObject,
  parentObj,
}) => {
  const data = useFetch(`${process.env.REACT_APP_API || ""}/api/items`, {
    options: {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({
        items: dataFromParent?.map((datum) => {
          return {
            name: datum.name,
            category: datum.category,
          };
        }),
      }),
    },
    ignoreIfTrue: !dataFromParent,
    watch: [dataFromParent],
  });

  return (
    <>
      <div className="details">
        <Heading variant="h2">Compare</Heading>
        {dataFromParent.length < 2 ? (
          <>
            {dataFromParent.length === 0 ? (
              <Text>Select at least two items to compare.</Text>
            ) : (
              <Text>
                Select one more item to compare with{" "}
                <strong>{dataFromParent[0].name}</strong>.
              </Text>
            )}
          </>
        ) : data ? (
          <>
            <LinkGroup
              heading={`Comparing ${data.length} items`}
              headingVariant="h3"
              primaryColor="blue"
              data={data.map((datum) => {
                return {
                  name: datum.name,
                  onClick: () => {
                    setDataFromParent(
                      dataFromParent.filter((d) => d.name !== datum.name)
                    );
                  },
                  icon: "close",
                };
              })}
            />
            <HistoricalTrends
              data={data}
              localScopeName={parentObj.name}
              textFormat="%VIEW% %CYCLE% %TIME_PERIOD% %SCOPE% %ADJUSTMENT%"
              parentObj={parentObj}
            />
            <Card
              color="blue"
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
            >
              <Heading variant="h3">Head to head</Heading>
              {data.length === 2 ? (
                <div className="head-to-head">
                  {data.map((datum, i) => (
                    <Heading key={i} variant="h4">
                      {datum.name}
                    </Heading>
                  ))}
                  <Text variant="caption">Category</Text>
                  {data.map((datum, i) => (
                    <Heading key={i} variant="h6">
                      {datum.category}
                    </Heading>
                  ))}
                  <Text variant="caption">
                    Rank in <strong>{parentObj.name}</strong>
                  </Text>
                  {data.map((datum, i) => (
                    <Heading key={i} variant="h6">
                      {parentObj.data
                        .sort((a, b) => b.listings - a.listings)
                        .findIndex((item) => item.name === datum.name) + 1}
                    </Heading>
                  ))}
                  <Text variant="caption">Total listings</Text>
                  {data.map((datum, i) => (
                    <Heading key={i} variant="h6">
                      {datum.listings.length > 0
                        ? parseInt(datum.listings[0].listings).toLocaleString(
                            "en",
                            {
                              useGrouping: true,
                            }
                          )
                        : "N/A"}
                    </Heading>
                  ))}
                </div>
              ) : (
                <Text>
                  Select exactly two items to compare them head to head.
                </Text>
              )}
            </Card>
            <LinkGroup
              heading="Dive deeper"
              headingVariant="h3"
              primaryColor="blue"
              data={data.map((datum) => {
                return {
                  name: datum.name,
                  onClick: () => setSelectedObject(datum),
                  icon: "go",
                };
              })}
            />
          </>
        ) : (
          <>
            <Placeholder
              style={{
                height: "3rem",
                width: "100%",
                marginBottom: "16px",
              }}
            />
            <Placeholder
              style={{
                height: "96px",
                width: "100%",
                marginBottom: "16px",
              }}
            />
            <Placeholder
              style={{
                height: "128px",
                width: "100%",
                marginBottom: "16px",
              }}
            />
          </>
        )}
      </div>
    </>
  );
};
