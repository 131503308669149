import React from "react";

import "./DataTransformer.css";

import RadioGroup from "../RadioGroup";

export const DataTransformer = ({
  color = "blue",
  options,
  setFn,
  value,
  setValue,
}) => {
  return (
    <>
      <RadioGroup
        color={color}
        options={options.map((opt) => {
          opt.value = opt.label;
          return opt;
        })}
        value={value}
        setValue={(newValue) => {
          const fn = options.find((opt) => opt.value === newValue)?.fn;
          if (fn) setFn(fn);

          setValue(newValue);
        }}
      />
    </>
  );
};
