import React from "react";

import "./HistoricalTrends.css";

import Card from "../Card";
import Dropdown from "../Dropdown";
import Heading from "../Heading";
import LineGraph from "../../visualizations/LineGraph";
import Text from "../Text";

import {
  ADJUSTMENT,
  CYCLE,
  SCOPE,
  TIMEPERIOD,
  TIMEPERIODNUMS,
  VIEW,
} from "../../enums/enums";

const ConfigDropdown = ({ color, configs, setConfigs, values, accessor }) => {
  return (
    <>
      <Dropdown
        color={color === "blue" ? "white" : "blue"}
        value={configs[accessor]}
        options={Object.keys(values).map((key) => {
          return {
            label: values[key],
            value: values[key],
          };
        })}
        variant="text"
        iconSize="xxxs"
        setValue={(newValue) => {
          const newConfigs = { ...configs };
          newConfigs[accessor] = newValue;
          setConfigs(newConfigs);
        }}
      />{" "}
    </>
  );
};

export const HistoricalTrends = ({
  color = "blue",
  data,
  localScopeName,
  textFormat = "",
  defaults = {
    timePeriod: TIMEPERIOD.PAST_YEAR,
    scope: SCOPE.GLOBAL,
    view: VIEW.TOTAL,
    adjustment: ADJUSTMENT.NONE,
    cycle: CYCLE.MONTH_OVER_MONTH,
  },
  parentObj,
}) => {
  const [configs, setConfigs] = React.useState(defaults);
  const hasTimePeriod = textFormat.includes(`%TIME_PERIOD%`);
  const hasScope = textFormat.includes(`%SCOPE%`);
  const hasView = textFormat.includes(`%VIEW%`);
  const hasAdjustment =
    configs.scope === SCOPE.LOCAL &&
    configs.view === VIEW.PERCENT &&
    textFormat.includes(`%ADJUSTMENT%`);
  const hasCycle = configs.view !== VIEW.TOTAL && textFormat.includes(`%CYCLE`);

  return (
    <Card
      color={color}
      style={{
        padding: "32px",
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <Heading variant="h3">Historical data</Heading>
      <Text variant="caption">
        {textFormat.split(" ").map((word) => {
          switch (word) {
            case "%TIME_PERIOD%":
              return (
                <ConfigDropdown
                  color={color}
                  configs={configs}
                  setConfigs={setConfigs}
                  values={TIMEPERIOD}
                  accessor="timePeriod"
                />
              );
            case "%SCOPE%":
              return (
                <ConfigDropdown
                  color={color}
                  configs={configs}
                  setConfigs={setConfigs}
                  values={SCOPE}
                  accessor="scope"
                />
              );
            case "%VIEW%":
              return (
                <ConfigDropdown
                  color={color}
                  configs={configs}
                  setConfigs={setConfigs}
                  values={VIEW}
                  accessor="view"
                />
              );
            case "%ADJUSTMENT%":
              return hasAdjustment ? (
                <ConfigDropdown
                  color={color}
                  configs={configs}
                  setConfigs={setConfigs}
                  values={ADJUSTMENT}
                  accessor="adjustment"
                />
              ) : null;
            case "%CYCLE%":
              return hasCycle ? (
                <ConfigDropdown
                  color={color}
                  configs={configs}
                  setConfigs={setConfigs}
                  values={CYCLE}
                  accessor="cycle"
                />
              ) : null;
            default:
              return word;
          }
        })}
      </Text>
      <LineGraph
        data={data
          .map((d) => {
            return d.listings
              .slice(
                0,
                hasTimePeriod
                  ? TIMEPERIODNUMS[configs.timePeriod] + 1
                  : d.listings.length
              )
              .map((l, i) => {
                return {
                  name: d.name,
                  date: l.date,
                  listings: (() => {
                    const getDiffElement = (array) =>
                      configs.cycle === CYCLE.MONTH_OVER_MONTH
                        ? array[i + 1]
                        : array[
                            Math.min(
                              TIMEPERIODNUMS[configs.timePeriod],
                              array.length - 1
                            )
                          ];
                    const adjustmentValue =
                      configs.adjustment === ADJUSTMENT.AVERAGE && parentObj
                        ? i === parentObj.listings.length - 1
                          ? 0
                          : (parseInt(parentObj.listings[i].listings) -
                              parseInt(
                                getDiffElement(parentObj.listings).listings
                              )) /
                            parseInt(
                              getDiffElement(parentObj.listings).listings
                            )
                        : 0;

                    if (configs.scope === SCOPE.GLOBAL) {
                      switch (configs.view) {
                        case VIEW.TOTAL:
                          return parseInt(l.listings);
                        case VIEW.ABSOLUTE:
                          return i === d.listings.length - 1
                            ? 0
                            : parseInt(l.listings) -
                                parseInt(getDiffElement(d.listings).listings);
                        case VIEW.PERCENT:
                          return i === d.listings.length - 1
                            ? 0
                            : (parseInt(l.listings) -
                                parseInt(getDiffElement(d.listings).listings)) /
                                parseInt(getDiffElement(d.listings).listings);
                      }
                    } else {
                      switch (configs.view) {
                        case VIEW.TOTAL:
                          return parseInt(
                            l.items.find((i) => i.name === localScopeName)
                              ?.listings
                          );
                        case VIEW.ABSOLUTE:
                          return i === d.listings.length - 1
                            ? 0
                            : parseInt(
                                l.items.find((i) => i.name === localScopeName)
                                  ?.listings
                              ) -
                                parseInt(
                                  getDiffElement(d.listings).items.find(
                                    (i) => i.name === localScopeName
                                  )?.listings
                                );
                        case VIEW.PERCENT:
                          return i === d.listings.length - 1
                            ? 0
                            : (parseInt(
                                l.items.find((i) => i.name === localScopeName)
                                  ?.listings
                              ) -
                                parseInt(
                                  getDiffElement(d.listings).items.find(
                                    (i) => i.name === localScopeName
                                  )?.listings
                                )) /
                                parseInt(
                                  getDiffElement(d.listings).items.find(
                                    (i) => i.name === localScopeName
                                  )?.listings
                                ) -
                                adjustmentValue;
                      }
                    }
                  })(),
                };
              });
          })
          .flat()}
        x={(d) => {
          return new Date(d.date.split("-")[1], d.date.split("-")[0] - 1, 1);
        }}
        xFormat="%b"
        y={(d) => d.listings}
        yFormat={configs.view === VIEW.PERCENT ? ".1%" : undefined}
        z={(d) => d.name}
        title={(d) => d.name}
      />
    </Card>
  );
};
