import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const AuthContext = createContext();

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  const history = useHistory();
  const location = useLocation();

  const ERRORS = {
    INVALID_EMAIL: "Enter a valid email address.",
    INCORRECT_PASSWORD: "Email or password is incorrect.",
  };

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  // Check if there is a currently active session when the provider is mounted for the first time.
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API || ""}/user`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        const user = data.user;
        if (user) {
          setUser(user);
        }
      })
      .catch(() => {
        console.log("error");
      })
      .finally(() => setLoadingInitial(false));
  }, []);

  // Flags the component loading state and posts the login data to the server.
  // An error means that the email/password combination is not valid.
  // Finally, just signal the component loading state is over.
  const signIn = (email, password) => {
    setLoading(true);

    if (!new RegExp("^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$").test(email)) {
      setErrorMessage(ERRORS.INVALID_EMAIL);
      return;
    }
    fetch(`${process.env.REACT_APP_API || ""}/user/signin`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        email,
        password,
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        const user = data.user;
        if (user) {
          setUser(user);
          history.push("/");
        } else {
          setErrorMessage(ERRORS.INCORRECT_PASSWORD);
          throw new Error();
        }
      })
      .catch((err) => {
        setError(err);
        setErrorMessage("Oops, something went wrong.");
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  // Sends sign up details to the server. On success we just apply the created user to the state.
  function signUp(email, name, password) {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API || ""}/user/signup`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        name,
        email,
        password,
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        const user = data.user;
        if (user) {
          history.push("/signin");
        } else {
          setErrorMessage(data.msg);
        }
      })
      .catch(() => {
        console.log("error");
      })
      .finally(() => setLoading(false));
  }

  // Call the logout endpoint and then remove the user
  // from the state.
  function signOut() {
    fetch(`${process.env.REACT_APP_API || ""}/user/signout`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 200) {
          setError(response.body.msg);
        } else {
          setUser(undefined);
          history.push("/");
        }
      })
      .catch((err) => {
        console.log("server error");
      });
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      errorMessage,
      signIn,
      signUp,
      signOut,
      setErrorMessage,
    }),
    [user, loading, error, errorMessage]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
