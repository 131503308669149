import "./App.css";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";

import LandingPage from "./pages/LandingPage";
import SearchPage from "./pages/SearchPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";

import Navbar from "./components/Navbar";

import StyleContainer from "./components/StyleContainer";
import React from "react";
import { AuthProvider } from "./hooks/useAuth";

function App() {
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = React.useState("");
  const [selectedObject, setSelectedObject] = React.useState(null);

  React.useEffect(() => {
    setSearchValue("");
    setSelectedObject(null);

    const params = new URLSearchParams(location.search);
    if (location.pathname.includes("search") && params.has("q")) {
      setSearchValue(params.get("q"));
    }

    if (
      location.pathname.includes("overview") &&
      params.has("name") &&
      params.has("category")
    ) {
      setSelectedObject({
        name: params.get("name"),
        category: params.get("category"),
      });
    }
  }, [location]);

  return (
    <AuthProvider>
      <div className="App">
        <StyleContainer theme="default">
          <Navbar />
          <Switch>
            <Route path="/overview">
              <SearchPage
                searchValue={searchValue}
                selectedObject={selectedObject}
                setSelectedObject={setSelectedObject}
              />
            </Route>
            <Route path="/signin">
              <SignInPage />
            </Route>
            <Route path="/signup">
              <SignUpPage />
            </Route>
            <Route path="/">
              {searchValue === "" ? (
                <LandingPage setSearchValue={setSearchValue} />
              ) : (
                <SearchPage
                  searchValue={searchValue}
                  selectedObject={selectedObject}
                  setSelectedObject={setSelectedObject}
                />
              )}
            </Route>
          </Switch>
        </StyleContainer>
      </div>
    </AuthProvider>
  );
}

export default App;
