import React from "react";

export const useFetch = (
  url,
  {
    options = {},
    transformer = (data) => data,
    ignoreIfTrue = false,
    watch = [],
  } = {}
) => {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    if (ignoreIfTrue) {
      setData(undefined);
      return;
    }

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        setData(transformer(data));
      })
      .catch(() => {});
  }, [url, ignoreIfTrue, ...watch]);

  return data;
};
