import React from "react";
import { Link, useHistory } from "react-router-dom";

import "./Navbar.css";

import Button from "../Button";
import Heading from "../Heading";
import Text from "../Text";
import TextLink from "../TextLink";
import useAuth from "../../hooks/useAuth";

export const Navbar = () => {
  const history = useHistory();
  const { user, signOut } = useAuth();

  return (
    <nav className="navbar">
      <div className="logo">
        <TextLink href="/">
          <Heading variant="cta">cumulus</Heading>
        </TextLink>
      </div>
      <div className="hide-on-mobile">
        <TextLink href="/about">About Us</TextLink>
      </div>
      {user ? (
        <>
          {/* TODO: ADD A POP-UP CARD OR PAGE REDIRECT WHEN THE NAME IS CLICKED */}
          <div className="cta-button">
            <Text>{user.name}</Text>
          </div>
          <div className="hide-on-mobile">
            <Button label="Sign Out" onClick={signOut} />
          </div>
        </>
      ) : (
        <>
          <div className="hide-on-mobile">
            <TextLink href="/signin">Sign In</TextLink>
          </div>
          <div className="cta-button">
            <Button
              color="cta"
              label="Join Us"
              onClick={() => history.push("/signup")}
            />
          </div>
        </>
      )}
    </nav>
  );
};
